import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from "../_services";
import { AuthComponent } from "./auth.component";
import { SigninComponent } from "./signin";
import { WaitComponent } from "./wait";


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [() => AuthGuard(false)],
    children: [
      {path: 'signin', component: SigninComponent},
      {path: 'wait', component: WaitComponent},
      {path: '**', redirectTo: 'signin'},
    ]
  },

  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
