<!-- Пользователь заблокирован. -->
<div
  *ngIf="tuser && tuser.isBlock"
  class="w-[300px] p-[7px] text-justify bg-bbRedLight text-bbYellowRegular text-2xl select-none rounded-[4px]">
  <p class="pb-3">Пользователь заблокирован. Вы не можете войти на данный ресурс.</p>
  <p class="pb-3">Если у вас возникли вопросы, пожалуйста свяжитесь с администратором.</p>
</div>
<!-- /Пользователь заблокирован. -->

<!-- Пользователь не активирован. -->
<div
  *ngIf="tuser && !tuser.isBlock"
  class="w-[300px] p-[7px] text-justify text-2xl select-none">
  <p class="pb-3">Вы успешно авторизовались, но ваш пользователь ожидает модерации.</p>
  <p class="pb-3">Если вы хотите ускорить процесс прохождения модерации, пожалуйста свяжитесь с администратором системы.</p>
  <p class="pb-3">Повторить попытку входа вы можете нажав на кнопку "Повторить вход".</p>
</div>
<!-- /Пользователь не активирован. -->

<!-- Кнопка сброса данных. -->
<button
  *ngIf="tuser && !tuser.isBlock"
  (click)="reset()"
  class="w-[300px] flex flex-row bg-bbBlueRegular font-golosTextBold text-bbWhite rounded-[4px]
       text-[14px] uppercase mt-[12px] hover:bg-bbGreenRegular px-[17px] py-[11px]">
  <div class="mx-auto">Повторить вход</div>
</button>
<!-- /Кнопка сброса данных. -->
