import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { ISessionCreateResponse } from "../../_services";
import { getTUserLoaded, Reset } from "../../_reducers";


@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnInit, OnDestroy {
  private tuserSubscribe?: Subscription = undefined;
  private readonly tuser$: Observable<ISessionCreateResponse | undefined>

  public tuser?: ISessionCreateResponse = undefined;

  /**
   * Конструктор.
   * @param store      - Хранилище NGRX.
   * @param router     - Сервис роутинга.
   */
  constructor(
    private store: Store<{ tuser: ISessionCreateResponse }>,
    private router: Router,
  ) {
    this.tuser$ = this.store.select(getTUserLoaded);
  }

  /* Инициализатор. */
  ngOnInit(): void {
    this.tuserSubscribe = this.tuser$.subscribe((tuser: ISessionCreateResponse | undefined) => {
      if (tuser === undefined) {
        this.router.navigateByUrl('/auth/auth').then((_: boolean) => {
        });
        return
      }
      this.tuser = tuser;
    });
  }

  /* Деструктор. */
  ngOnDestroy(): void {
    if (this.tuserSubscribe !== undefined) {
      this.tuserSubscribe.unsubscribe();
      this.tuserSubscribe = undefined;
    }
  }

  /** Сброс значения переменной, приведёт к переходу на страницу авторизации. */
  public async reset() {
    this.store.dispatch(Reset());
  }
}
