import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TelegramAuthModule } from "../_components";
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { SigninComponent } from './signin';
import { WaitComponent } from './wait';


@NgModule({
  declarations: [
    AuthComponent,
    SigninComponent,
    WaitComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    TelegramAuthModule,
  ]
})
export class AuthModule {
}
